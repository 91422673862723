*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
:root{
    --background: #E5E5E5;
    --blue: #21B5EA; 
    --gray: #F7F4F9;
    --black: #000000;
    --lilac: #6E45E2;
}
html{
    @media (max-width: 1080px){
        font-size: 93.75%;
    }
    @media (max-width: 720px){
        font-size: 87.5%;
    }
}
body{
    background: var(--background);
    color: var(--textos);
}
body, input, button, textarea, select{
    font-size: 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    line-height: 24px;
}
h1,h2,h3,h4,h5 strong{
    font-weight: 700;
}
a{
    color: initial;
    text-decoration: none;
}
button{
    cursor: pointer;
}
[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
}
.container{
    margin: auto;
    max-width: 1000px;
    padding: 0 1rem;
    @media (max-width: 1200px){
        max-width: 960px;
    }
    @media (max-width: 992px){
        max-width: 720px;
    }
    @media (max-width: 768px){
        max-width: 100%;
    }
}
.ncc{
    background-color: #0c6c87;
}
.nccBottom{
    background: #0c6c87;
    height: 35px;
}
.pleno{
    background: linear-gradient(93.01deg, #FC7061 -16.49%, #FF176B 149.97%);
}
.plenoBottom{
    background: #21B5EA;
    height: 35px;
}
.App{
    grid-template-rows: auto 1fr auto;
    height: 100vh;
    display: grid;
}
.plenoBtn{
    background-color: var(--blue);
}
.nccBtn{
    background-color: var(--lilac);
}