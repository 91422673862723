.footer{

    .footerContent{
        height: 228px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content{
            display: flex;
            flex-direction: column;
            color: #ffffff;
            
            h2{
                font-size: 1.188rem;
                padding: 5px 0;
            }
            span{
                color: #ffffff;
                font-size: 1.2rem;
                padding: 5px 0;
            }
            img{
                padding-right: 7px;
            }
        }
    }
    .text{
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #FFFFFF;
        max-width: 414px;
    }
    .footerBottom{
        text-align: center;
        padding: 5px 0;
    }
    @media (min-width: 500px) and (max-width: 1024px){
        .footerContent{
            height: auto;
            padding: 1.5rem 15px;
            align-items: center;
            .logo{
                img{
                    height: calc(15vw);
                }
            }
            .content{
                h2{
                 font-size: 1rem;   
                }
             div{
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 0;
                span{
                 font-size: 1rem;   
                }
                img{
                   height: 10px;
                }
             }
            }
            .text{
                text-align: center;
                font-size: 1rem;
                line-height: 1;
                margin-bottom: 5px;
                max-width: 280px;
            }
        }
}
    @media (max-width: 500px){
        .footerContent{
            height: auto;
            flex-direction: column;
            align-items: center;
            padding: 1.5rem;
            .logo{
                order: 3;
                img{
                    height: calc(30vw);
                }
            }
            .content{
             order: 2;
             padding-top: 6px;
             div{
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 0;
                span{
                 font-size: 1rem;   
                }
                img{
                   height: 10px;
                }
             }
            }
            .text{
                text-align: center;
                font-size: 0.875rem;
                line-height: 1;
                margin-bottom: 5px;
                order: 1;
            }
        }
    }
}