.barCode{
    margin: 68px 0;
    display: flex;
    align-items: center;
    
    .title{
        font-family: 'Inter';
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        margin-bottom: 15px;
        max-width: 681px;
        margin-left: auto;
        margin-right: auto;
    }
    .input{
        display: flex;
        justify-content: center;

        input{
            background: rgba(99, 36, 127, 0.05);
            border-radius: 15px;
            width: 100%;
            max-width: 520px;
            height: 60px;
            padding: 15px;
            margin-right: 15px;

            &:focus-visible{
                border: 1px solid #000000;
            }
            &::-webkit-inner-spin-button, &::-webkit-outer-spin-button{
                -webkit-appearance: none;
                margin: 0;
                display: none;
            }
        }
    }
    .buttons{
        display: flex;
        align-items: center;
        gap: 15px;
        .copyContainer{
            position: relative;
            &:hover{
                .hoverClone{
                    display: block;
                }
            }
            .hoverClone{
                position: absolute;
                top: -43px;
                left: 7px;
                background: #333333;
                border-radius: 7.36842px;
                padding: 7.5px 6.6px;
                display: none;
                line-height: 1;
                span{
                    font-family: 'Inter';
                    font-size: 0.875rem;
                    line-height: 17px;
                    color: #ffffff;
                    font-weight: 700;
                }
                .arrowBottom{
                    position: absolute;
                    bottom: -12px;
                    left: calc(50% - 11px);
                }
            }
        }
        .confirmnContainer{
            position: relative;
            &:hover{
                .hoverConfirmn{
                    display: block;
                }
            }
            .hoverConfirmn{
                position: absolute;              
                top: -52px;
                left: -6px;
                display: none;
                background: #333333;
                border-radius: 7.36842px;
                padding: 10px 9px;
                line-height: 1;
                span{
                    font-family: 'Inter';
                    font-size: 19px;
                    line-height: 23px;
                    color: #ffffff;
                    font-weight: 700;
                }
                .arrowBottom{
                    position: absolute;
                    bottom: -12px;
                    left: calc(50% - 11px);
                }
            }
        }
        .clone{
            background: #F7F4F9;
            width: 65px;
            height: 60px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        button{
            width: 65px;
            height: 60px;
            border-radius: 15px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.confirmn{
                border: none;
            }
        }
    }
    .alert{

        .contentMessage{
            text-align: center;
            margin-top: 40px;
            p{
                font-family: 'Inter';
                font-weight: 700;
                font-size: 1.188rem;
                line-height: 23px;
            }
            h2{
                font-size: 1.813rem;
                line-height: 35px;
                font-family: 'Inter';
                font-weight: 700;
                margin-bottom: 15px;

                &.true{
                    color: #48D704;
                }
                &.false{
                    color: #FF0000;
                }
            }

        }
    }
    @media (max-width: 500px){
        &{
            margin: 60px 0px;
            display: flex;
            align-items: center;
        }
        .title{
            font-size: 0.875rem;
            line-height: 1;
        }
        .input{
            flex-direction: column;
            align-items: center;
            gap: 15px;
            
            input{
                margin-right: 0px;
                height: 40px;
            }
            .buttons{
                justify-content: end;
                width: 100%;
                .copyContainer{
                    .hoverClone{
                        top: -40px;
                        left: 0px;
                    }
                }
                .confirmnContainer{
                    .hoverConfirmn{
                        top: -46px;
                        left: -5px;
                        span{
                            font-size: 0.875rem;
                            line-height: 17px;
                        }
                    }
                }
                .clone{
                    width: 45px;
                    height: 40px;
                    img{
                        height: 20px;
                    }
                }
                button{
                    width: 45px;
                    height: 40px;

                    img{
                        height: 20px;
                    }
                }
            }
        }
        .alert{
            .contentMessage{
                p{
                    font-size: 1rem;
                }
                h2{
                    font-size: 1.5rem;
                    margin-bottom: 0px;
                }
            }

        }
    }
}