.banner{
    height: calc(25vw);
    .bannerImg{
        height: 100%;
        background-size: cover;
    }
    @media (min-width: 501px) and (max-width: 1000px){
    &{
        height: calc(40vw);
        .bannerImg{
            background-position: 15%;       
        }
    }   
    }
    @media(max-width: 500px){
        &{
            height: calc(47vw);
            .bannerImg{
                background-position: 28%;       
            }
        }
    }
}